var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page1" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "el-tabs",
          {
            staticClass: "bgFFF",
            staticStyle: { "padding-left": "10px" },
            on: { "tab-click": _vm.handleTab },
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "一级分类", name: "1" } },
              [_c("levelOne", { ref: "knowledge" })],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "二级分类", name: "2" } },
              [_c("levelTwo", { ref: "levelTwo" })],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }